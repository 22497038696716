.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background: url(RESOURCE/img/slide-people-1980.jpg);
		background-position: center;

		background-repeat: no-repeat;
		background-size: cover;

	}
}