.unit-offers-v1 {
	#collapseOffer {
		padding: 15px;
	}

	.widget-block {
		position: relative;
	}

	.btn-offers {
		position: absolute;
		bottom: -13px;
		left: 50%;
		transform: translate(-50%);
		background: white;
		border: 1px solid var(--btn-border-color-hide-show);
		padding: 5px 15px;
	}

	.unit-offer {
		padding: 30px;
		background-color: var(--color-primary-light);
		position: relative;
		margin-bottom: 30px;

		.offer-minstay {
			font-size: var(--font-size-sm);

		}

		.offer-minstay-xy {
			font-size: var(--font-size-md);

		}

		.offer-condition {
			font-size: var(--font-size-md);
		}

		.offer-banner {
			position: absolute;
			background-color: var(--color-red);
			color: var(--color-white);
			top: -13px;
			left: -8px;
			padding: 2px 10px;
			text-transform: uppercase;
			font-size: var(--font-size-sm);
			border-radius: 5px;
			box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);

		}


	}
}