.faq-v2 {
	margin-top: var(--page-margin-top);

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}



	}

	.faq-group {
		h4 {
			font-size: 16px;
		}
	}


	ul {
		padding-left: 30px;
		list-style: disc;
	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}