.unit-pricelist-v1 {

	.widget-block {
		position: relative;
	}

	.btn-pricelist {
		position: absolute;
		bottom: -13px;
		left: 50%;
		transform: translate(-50%);
		background: white;
		border: 1px solid var(--btn-border-color-hide-show);
		padding: 5px 15px;
	}


	.pricelist {
		.mainrow {

			td {
				font-weight: 500;
			}
		}

		.subrow {

			td {
				vertical-align: bottom;
			}
		}
	}
}