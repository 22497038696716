.module .collapse,
.module .collapsing {
	height: 8rem;
}

.module .collapse {
	position: relative;
	display: block;
	overflow: hidden;
}

.module .collapse:before {
	position: absolute;
	right: 0;
	bottom: 0;
}

.module .collapse.show {
	height: auto;
}

.module .collapse.show:before {
	display: none;
}

.module a.collapsed {
	margin-top: 20px;
}

.module a.collapsed:after {
	content: 'Mehr anzeigen \f107';
	font-family: 'FontAwesome', 'Poppins';
}

.module a:not(.collapsed):after {
	content: 'Weniger anzeigen \f106';
	font-family: 'FontAwesome', 'Poppins';
}