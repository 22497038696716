.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax5.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.test-map {
		width: 100%;
		height: 300px;

	}

	.parallax-box {
		background-color: var(--color-primary-light);
		padding: 20px;
		min-height: 300px;

		.title {
			color: var(--color-primary);

		}

		p {
			text-align: justify;
		}

		.text {
			color: var(--color-primary);
		}
	}

	iframe {
		border-radius: 10px;

		@media (max-width: 767px) {
			height: 350px;
		}
	}

}