.blogentry-v1 {
	/* padding-top: var(--page-margin-top); */
	height: auto;
	padding-bottom: 50px;

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
		padding-bottom: 10px;
		margin-bottom: 30px;

		h2.heading1 {
			border: none;
			margin: 0 0 30px 0;
			padding: 0;
		}
	}
}